



































































import { AnyObject, EventbusType, IEventbus, ResourceActionFailed } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import { ConnectorErrors, Inject, logger } from '../../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import Consents from '../../molecules/Consents/Consents.vue'
import { Form } from '../../molecules/Form'

import { CONTACT_FORM_COMPONENT_KEY, defaultComponentConfig } from './ContactForm.config'
import { contactFormValidationsMap } from './ContactForm.helpers'

@Component<ContactForm>({
  name: 'ContactForm',
  components: { Consents, Form },
  created (): void {
    this.config = this.getComponentConfig(CONTACT_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class ContactForm extends Mixins(FormErrorsMixin, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public isThankYou: boolean = false
  public payload: AnyObject = {
    email: '',
    name: '',
    subject: '',
    message: '',
    acceptPrivacy: false,
    emailOffers: false,
    smsOffers: false
  } as AnyObject

  public validatorsMap = contactFormValidationsMap

  public get animatedLabel (): AnyObject[] {
    return this.getConfigProperty('input').animatedLabel
  }

  /**
   * Determines the consents of form.
   */
  public get consents (): AnyObject[] {
    return this.getConfigProperty<AnyObject[]>('consents')
  }

  /**
   * Determines the subjects of form.
   */
  public get subjects (): AnyObject[] {
    return this.getConfigProperty<AnyObject[]>('subjects')
  }

  public async submit (): Promise<void> {
    this.cleanError()
    this.eventBus.emit('app:contactForm.submit', this.payload)

    this.isThankYou = true
  }

  /**
   * Cleans the error
   */
  private cleanError (): void {
    this.error = null
  }

  protected setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.error = error.message
  }
}

export default ContactForm
