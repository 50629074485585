








import { Component, Mixins, Watch } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { FormErrorsMixin } from '../../../../../../support/mixins'
import { Inject } from '../../../../../../support'

import { SimpleFormMixin } from '../../../../mixins/simpleForm.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Basic>({
  name: 'Basic'
})
export class Basic extends Mixins(SimpleFormMixin, FormErrorsMixin) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Watch('errors')
  public onErrorsChange () {
    this.eventBus.emit('ui:user-data:errors', this.errors)
  }
}

export default Basic
