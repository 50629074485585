import { AnyObject } from '@movecloser/front-core'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const CONTACT_FORM_COMPONENT_KEY = 'ContactFormComponent'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  consents: [],
  subjects: [],
  input: {
    animatedLabel: true
  }
}
