


import { AnyObject, ResourceActionFailed } from '@movecloser/front-core'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConnectorErrors } from '../../../../support'

import { ValidationResult } from '../../services/validator'
import { validatePayload } from '../../support/validate-payload'

import { FormErrors } from './FormErrors'
import { ValidationRules } from './Form.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<AbstractForm>({ name: 'AbstractForm' })
export class AbstractForm extends Vue {
  /**
   * Promise function to fire on form.submit.
   */
  @Prop({ type: Object, required: false })
  public formData!: AnyObject

  /**
   * Promise function to fire on form.submit.
   */
  @Prop({ type: Function, required: false })
  public onSubmit?: (payload: any) => Promise<void>

  /**
   *  Determines object of validators for payload
   */
  @Prop({ type: Object, required: false })
  public validatorsMap!: ValidationRules

  /**
   * Object to store form errors
   */
  public errors: null | Record<string, string[]> = null

  /**
   * Determines the state of loading.
   */
  public isLoading: boolean = false

  protected get locale (): string {
    return this.$i18n.locale.toLowerCase()
  }

  /**
   * Adds new errors to the errors array.
   */
  protected addErrors (key: string, errors: string[]): void {
    if (!this.errors) {
      this.errors = { [key]: errors }
      this.$emit('errors', this.errors)
      return
    }

    this.errors = {
      ...this.errors,
      [key]: errors
    }

    this.$emit('errors', this.errors)
  }

  /**
   * Submits the form
   */
  public async submit (): Promise<void> {
    // debugger
    if (typeof this.onSubmit !== 'function') {
      this.$emit('submit')

      return
    }

    if (typeof this.validatorsMap !== 'undefined' || typeof this.formData === 'undefined') {
      this.validatePayload()
    }

    if (
      this.errors !== null &&
      Object.keys(this.errors).length > 0
    ) {
      return
    }

    this.isLoading = true

    try {
      const response: unknown = await this.onSubmit(this.formData)
      this.$emit('success', response)
    } catch (e) {
      const error = e as Error

      if (
        error instanceof FormErrors ||
        (error instanceof ResourceActionFailed && error.status === ConnectorErrors.Validation)
      ) {
        for (const key of Object.keys(error.payload)) {
          this.addErrors(key, error.payload[key])
        }

        this.$emit('fail')
      } else {
        this.$emit('fail', error)
      }
    }

    this.errors = null
    this.isLoading = false
  }

  /**
   * Validates form's payload.
   *
   * @summary - This method is responsible for validation of payload of the form.
   *            By default it takes care about initial `payload`. You can use this
   *            if you need to validate another `data` inside this component just
   *            by passing another payload as an `arg`.
   *
   * @param payload - payload to verify. Defaults to `this.payload` object.
   * @param validatorsMap - Map which is used to validate payload.
   * @returns - void
   */
  public validatePayload (
    payload?: AnyObject,
    validatorsMap?: ValidationRules
  ): void {
    if (!payload) {
      payload = this.formData
    }

    if (!validatorsMap) {
      validatorsMap = this.validatorsMap
    }
    const result: ValidationResult = validatePayload(
      payload,
      validatorsMap,
      this.$t.bind(this),
      this.locale
    )

    if (!result.errors) {
      return
    }

    for (const [key, errors] of Object.entries(result.errors)) {
      this.addErrors(key, errors)
    }
  }

  @Watch('formData', { deep: true })
  private onFormDataChange (): void {
    this.errors = null
  }
}

export default AbstractForm
