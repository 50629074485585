
import { VueConstructor } from 'vue'

import Basic from './versions/Basic.vue'
import Toggleable from './versions/Toggleable.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum SimpleFormLayout {
  Basic = 'basic',
  Toggleable = 'toggleable'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SIMPLE_FORM_COMPONENT_KEY = 'SimpleForm'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface SimpleFormConfig {
  layout: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SIMPLE_FORM_COMPONENT_CONFIG_MAP: SimpleFormConfig = {
  layout: SimpleFormLayout.Toggleable
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const simpleFormComponentsRegistry: Record<string, VueConstructor> = {
  [SimpleFormLayout.Basic]: Basic,
  [SimpleFormLayout.Toggleable]: Toggleable
}
