






import { Component, Mixins } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ContactFormModule } from '../ContactForm.contracts'
import ContactForm from '../../../front/shared/organisms/ContactForm/ContactForm.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ContactFormModuleUi>({
  name: 'ContactFormModuleUi',
  components: { ContactForm }
})
export class ContactFormModuleUi extends Mixins<AbstractModuleUi<ContactFormModule>>(
  AbstractModuleUi) {
  public get shouldRender (): boolean {
    return true
  }
}

export default ContactFormModuleUi
